<template>
  <div>
    <b-card class="invoice-preview-card">
      <div class="d-flex justify-content-between flex-wrap">
        <h2 class="my-auto">{{ $t("tourism_jobs.title") }} <br /></h2>
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="warning"
          @click="
            () => {
              $refs.modal_export_stat.show();
            }
          "
        >
          <b-spinner v-if="isDownloading" small class="align-middle" />
          <i v-else class="las la-file-download" />
          {{ $t('button.download_stats') }}
        </b-button>
      </div>
    </b-card>
    <b-row class="px-2" sm="12">
      <!-- ueav -->
      <b-col cols="12" sm="12" md="6" lg="4" xl="3">
        <b-card>
          <div class="d-flex align-items-center justify-content-start w-100">
            <div class="">
              <div class="icon" style="background-color: #d9f9e1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#3c6e4b"
                    d="M16 3c-1.645 0-3 1.355-3 3v7.344l-8.406 3.75l-.594.25v4.781l9-1v1.844l-2.563 1.718l-.437.282v4.25l1.188-.25L16 28l4.813.969l1.187.25v-4.25l-.438-.282L19 22.97v-1.844l9 1v-4.781l-.594-.25L19 13.344V6c0-1.645-1.355-3-3-3zm0 2c.566 0 1 .434 1 1v8.656l.594.25L26 18.656v1.219l-9-1v5.188l.438.28L20 26.064v.718l-3.813-.75L16 25.97l-.188.062l-3.812.75v-.718l2.563-1.72l.437-.28v-5.188l-9 1v-1.219l8.406-3.75l.594-.25V6c0-.566.434-1 1-1z"
                  />
                </svg>
              </div>
            </div>
            <h4 class="mb-0 ml-1 text-clr">
              {{ $t("sectors.agency_travel") }}
            </h4>
          </div>
          <b-row class="mt-1">
            <!-- sucess -->
            <b-col
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-check-double text-success la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_success") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueav[0].treatment_completed_successfully }}
                </p>
              </div>
            </b-col>
            <!-- failed -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i class="las la-times text-danger la-lg" aria-hidden="true" />
                <p class="title">
                  {{ $t("dashboard.treate_request_failed") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueav[2].treatment_completed_failure }}
                </p>
              </div>
            </b-col>
            <hr class="py-1" />
            <!-- on_process -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-hourglass text-danger la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_on") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueav[1].on_hold }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- uegt -->
      <b-col cols="12" sm="12" md="6" lg="4" xl="3">
        <b-card>
          <div class="d-flex align-items-center justify-content-start w-100">
            <div class="">
              <div class="icon" style="background-color: #f9f8d5">
                <!--    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                  <path fill="#b38e38"
                    d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm3.281 3.281L22 22.563l-2.281-2.282l-1.438 1.438l3 3l.719.687l.719-.687l4-4z" />
                </svg> -->
                <i class="las la-map-marked-alt icons" style="color: #b38e38" />
              </div>
            </div>
            <h4 class="mb-0 ml-1 text-clr">
              {{ $t("sectors.tourism_guide") }}
            </h4>
          </div>
          <b-row class="mt-1">
            <!-- sucess -->
            <b-col
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-check-double text-success la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_success") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uegt[0].treatment_completed_successfully }}
                </p>
              </div>
            </b-col>
            <!-- failed -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i class="las la-times text-danger la-lg" aria-hidden="true" />
                <p class="title">
                  {{ $t("dashboard.treate_request_failed") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uegt[2].treatment_completed_failure }}
                </p>
              </div>
            </b-col>
            <hr class="py-1" />
            <!-- on_process -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-hourglass text-danger la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_on") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uegt[1].on_hold }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- ueh -->
      <b-col cols="12" sm="12" md="6" lg="4" xl="3">
        <b-card>
          <div class="d-flex align-items-center justify-content-start w-100">
            <div class="">
              <div class="icon" style="background-color: #ffe4e6">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="25"
                  viewBox="0 0 32 32"
                >
                  <path
                    fill="#cc6565"
                    d="M4 3v26h11v-4h2v4h11V3zm2 2h20v22h-7v-4h-6v4H6zm2 2v2h4V7zm6 0v2h4V7zm6 0v2h4V7zM8 11v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 15v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 19v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 23v2h4v-2zm12 0v2h4v-2z"
                  />
                </svg>
              </div>
            </div>
            <h4 class="mb-0 ml-1 text-clr">
              {{ $t("sectors.hebergement") }}
            </h4>
          </div>
          <b-row class="mt-1">
            <!-- sucess -->
            <b-col
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-check-double text-success la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_success") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueh[0].treatment_completed_successfully }}
                </p>
              </div>
            </b-col>
            <!-- failed -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i class="las la-times text-danger la-lg" aria-hidden="true" />
                <p class="title">
                  {{ $t("dashboard.treate_request_failed") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueh[2].treatment_completed_failure }}
                </p>
              </div>
            </b-col>
            <hr class="py-1" />
            <!-- on_process -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-hourglass text-danger la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_on") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.ueh[1].on_hold }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <!-- uer -->
      <b-col cols="12" sm="12" md="6" lg="4" xl="3">
        <b-card>
          <div class="d-flex align-items-center justify-content-start w-100">
            <div class="">
              <div class="icon" style="background-color: #d4eff4">
                <!--                 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                  <path fill="#4762af"
                    d="m12.375 3.813l-.313.843l-8 21.907l-.218.593l1 1l.593-.218l21.907-8l.843-.313l-.218-.875s-1.41-5.629-5.25-9.469s-9.469-5.25-9.469-5.25zm1.219 2.468c1.136.352 4.836 1.586 7.687 4.438c2.852 2.851 4.086 6.55 4.438 7.687l-1.532.563a10.464 10.464 0 0 0-.437-1.407c-.61-1.511-1.746-3.496-3.781-5.53c-2.035-2.036-4.02-3.141-5.532-3.75c-.539-.216-1-.36-1.406-.47zm-1.219 3.407c.336.09.766.218 1.313.437c1.289.52 3.046 1.484 4.874 3.313c.536.535.977 1.05 1.375 1.562A2.004 2.004 0 0 0 18 17a2 2 0 0 0 2 2c.738 0 1.371-.402 1.719-1c.043.102.117.215.156.313c.227.562.348 1.007.438 1.343l-10 3.625A1.483 1.483 0 0 0 11 22.5a1.5 1.5 0 0 0-1.469 1.813l-2.906 1.062l3.5-9.594A2 2 0 0 0 11 16a2 2 0 0 0 .5-3.938zM14 17.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" />
                </svg> -->
                <i class="las la-utensils icons" style="color: #4762af" />
              </div>
            </div>
            <h4 class="mb-0 ml-1 text-clr">
              {{ $t("sectors.restaurant") }}
            </h4>
          </div>
          <b-row class="mt-1">
            <!-- sucess -->
            <b-col
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-check-double text-success la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_success") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uer[0].treatment_completed_successfully }}
                </p>
              </div>
            </b-col>
            <!-- <hr class="py-1" /> -->
            <!-- failed -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i class="las la-times text-danger la-lg" aria-hidden="true" />
                <p class="title">
                  {{ $t("dashboard.treate_request_failed") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uer[2].treatment_completed_failure }}
                </p>
              </div>
            </b-col>
            <hr class="py-1" />
            <!-- on_process -->
            <b-col
              cols="12"
              class="d-flex align-items-center justify-content-between w-100"
            >
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <i
                  class="las la-hourglass text-danger la-lg"
                  aria-hidden="true"
                />
                <p class="title">
                  {{ $t("dashboard.treate_request_on") }}
                </p>
              </div>
              <div>
                <b-spinner small v-if="isLoading == true" />
                <p class="weight-800 text-clr" v-else>
                  {{ listData.uer[1].on_hold }}
                </p>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <!-- modal export stat -->
    <b-modal
      id="modal_export_stat"
      ref="modal_export_stat"
      hide-backdrop
      ok-only
      no-close-on-backdrop
      content-class="shadow"
      title="Aperçu"
      ok-title="Imprimer"
      size="lg"
      centered
      @ok="exportation_stat()"
    >
      <b-row class="px-2" sm="12">
        <!-- ueav -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon" style="background-color: #d9f9e1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#3c6e4b"
                      d="M16 3c-1.645 0-3 1.355-3 3v7.344l-8.406 3.75l-.594.25v4.781l9-1v1.844l-2.563 1.718l-.437.282v4.25l1.188-.25L16 28l4.813.969l1.187.25v-4.25l-.438-.282L19 22.97v-1.844l9 1v-4.781l-.594-.25L19 13.344V6c0-1.645-1.355-3-3-3zm0 2c.566 0 1 .434 1 1v8.656l.594.25L26 18.656v1.219l-9-1v5.188l.438.28L20 26.064v.718l-3.813-.75L16 25.97l-.188.062l-3.812.75v-.718l2.563-1.72l.437-.28v-5.188l-9 1v-1.219l8.406-3.75l.594-.25V6c0-.566.434-1 1-1z"
                    />
                  </svg>
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t("sectors.agency_travel") }}
              </h4>
            </div>
            <b-row class="mt-1">
              <!-- sucess -->
              <b-col
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-check-double text-success la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_success") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueav[0].treatment_completed_successfully }}
                  </p>
                </div>
              </b-col>
              <!-- failed -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-times text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_failed") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueav[2].treatment_completed_failure }}
                  </p>
                </div>
              </b-col>
              <hr class="py-1" />
              <!-- on_process -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-hourglass text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_on") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueav[1].on_hold }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- uegt -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon" style="background-color: #f9f8d5">
                  <!--    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                  <path fill="#b38e38"
                    d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm3.281 3.281L22 22.563l-2.281-2.282l-1.438 1.438l3 3l.719.687l.719-.687l4-4z" />
                </svg> -->
                  <i
                    class="las la-map-marked-alt icons"
                    style="color: #b38e38"
                  />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t("sectors.tourism_guide") }}
              </h4>
            </div>
            <b-row class="mt-1">
              <!-- sucess -->
              <b-col
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-check-double text-success la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_success") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uegt[0].treatment_completed_successfully }}
                  </p>
                </div>
              </b-col>
              <!-- failed -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-times text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_failed") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uegt[2].treatment_completed_failure }}
                  </p>
                </div>
              </b-col>
              <hr class="py-1" />
              <!-- on_process -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-hourglass text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_on") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uegt[1].on_hold }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- ueh -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon" style="background-color: #ffe4e6">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 32 32"
                  >
                    <path
                      fill="#cc6565"
                      d="M4 3v26h11v-4h2v4h11V3zm2 2h20v22h-7v-4h-6v4H6zm2 2v2h4V7zm6 0v2h4V7zm6 0v2h4V7zM8 11v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 15v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 19v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 23v2h4v-2zm12 0v2h4v-2z"
                    />
                  </svg>
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t("sectors.hebergement") }}
              </h4>
            </div>
            <b-row class="mt-1">
              <!-- sucess -->
              <b-col
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-check-double text-success la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_success") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueh[0].treatment_completed_successfully }}
                  </p>
                </div>
              </b-col>
              <!-- failed -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-times text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_failed") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueh[2].treatment_completed_failure }}
                  </p>
                </div>
              </b-col>
              <hr class="py-1" />
              <!-- on_process -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-hourglass text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_on") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.ueh[1].on_hold }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <!-- uer -->
        <b-col cols="12">
          <b-card>
            <div class="d-flex align-items-center justify-content-start w-100">
              <div class="">
                <div class="icon" style="background-color: #d4eff4">
                  <!--                 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                  <path fill="#4762af"
                    d="m12.375 3.813l-.313.843l-8 21.907l-.218.593l1 1l.593-.218l21.907-8l.843-.313l-.218-.875s-1.41-5.629-5.25-9.469s-9.469-5.25-9.469-5.25zm1.219 2.468c1.136.352 4.836 1.586 7.687 4.438c2.852 2.851 4.086 6.55 4.438 7.687l-1.532.563a10.464 10.464 0 0 0-.437-1.407c-.61-1.511-1.746-3.496-3.781-5.53c-2.035-2.036-4.02-3.141-5.532-3.75c-.539-.216-1-.36-1.406-.47zm-1.219 3.407c.336.09.766.218 1.313.437c1.289.52 3.046 1.484 4.874 3.313c.536.535.977 1.05 1.375 1.562A2.004 2.004 0 0 0 18 17a2 2 0 0 0 2 2c.738 0 1.371-.402 1.719-1c.043.102.117.215.156.313c.227.562.348 1.007.438 1.343l-10 3.625A1.483 1.483 0 0 0 11 22.5a1.5 1.5 0 0 0-1.469 1.813l-2.906 1.062l3.5-9.594A2 2 0 0 0 11 16a2 2 0 0 0 .5-3.938zM14 17.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" />
                </svg> -->
                  <i class="las la-utensils icons" style="color: #4762af" />
                </div>
              </div>
              <h4 class="mb-0 ml-1 text-clr">
                {{ $t("sectors.restaurant") }}
              </h4>
            </div>
            <b-row class="mt-1">
              <!-- sucess -->
              <b-col
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-check-double text-success la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_success") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uer[0].treatment_completed_successfully }}
                  </p>
                </div>
              </b-col>
              <!-- <hr class="py-1" /> -->
              <!-- failed -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-times text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_failed") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uer[2].treatment_completed_failure }}
                  </p>
                </div>
              </b-col>
              <hr class="py-1" />
              <!-- on_process -->
              <b-col
                cols="12"
                class="d-flex align-items-center justify-content-between w-100"
              >
                <div
                  class="d-flex align-items-center justify-content-start w-100"
                >
                  <i
                    class="las la-hourglass text-danger la-lg"
                    aria-hidden="true"
                  />
                  <p class="title">
                    {{ $t("dashboard.treate_request_on") }}
                  </p>
                </div>
                <div>
                  <b-spinner small v-if="isLoading == true" />
                  <p class="weight-800 text-clr" v-else>
                    {{ listData.uer[1].on_hold }}
                  </p>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <!-- impression stats -->
    <vue-html2pdf
      v-show="false"
      ref="printableStat"
      class="border rounded"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :manual-pagination="true"
      :filename="`Statistiques Métiers touristiques`"
      :pdf-quality="2"
      :pdf-margin="0"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="100%"
    >
      <section slot="pdf-content">
        <b-row class="px-2" sm="12">
          <!-- ueav -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon" style="background-color: #d9f9e1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#3c6e4b"
                        d="M16 3c-1.645 0-3 1.355-3 3v7.344l-8.406 3.75l-.594.25v4.781l9-1v1.844l-2.563 1.718l-.437.282v4.25l1.188-.25L16 28l4.813.969l1.187.25v-4.25l-.438-.282L19 22.97v-1.844l9 1v-4.781l-.594-.25L19 13.344V6c0-1.645-1.355-3-3-3zm0 2c.566 0 1 .434 1 1v8.656l.594.25L26 18.656v1.219l-9-1v5.188l.438.28L20 26.064v.718l-3.813-.75L16 25.97l-.188.062l-3.812.75v-.718l2.563-1.72l.437-.28v-5.188l-9 1v-1.219l8.406-3.75l.594-.25V6c0-.566.434-1 1-1z"
                      />
                    </svg>
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t("sectors.agency_travel") }}
                </h4>
              </div>
              <b-row class="mt-1">
                <!-- sucess -->
                <b-col
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-check-double text-success la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_success") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueav[0].treatment_completed_successfully }}
                    </p>
                  </div>
                </b-col>
                <!-- failed -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-times text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_failed") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueav[2].treatment_completed_failure }}
                    </p>
                  </div>
                </b-col>
                <hr class="py-1" />
                <!-- on_process -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-hourglass text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_on") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueav[1].on_hold }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- uegt -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon" style="background-color: #f9f8d5">
                    <!--    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                    <path fill="#b38e38"
                      d="M12 2C8.145 2 5 5.145 5 9c0 2.41 1.23 4.55 3.094 5.813C4.527 16.343 2 19.883 2 24h2c0-4.43 3.57-8 8-8c1.375 0 2.656.36 3.781.969A8.007 8.007 0 0 0 14 22c0 4.406 3.594 8 8 8c4.406 0 8-3.594 8-8c0-4.406-3.594-8-8-8a7.964 7.964 0 0 0-4.688 1.531a10.134 10.134 0 0 0-1.406-.719A7.024 7.024 0 0 0 19 9c0-3.855-3.145-7-7-7zm0 2c2.773 0 5 2.227 5 5s-2.227 5-5 5s-5-2.227-5-5s2.227-5 5-5zm10 12c3.324 0 6 2.676 6 6s-2.676 6-6 6s-6-2.676-6-6s2.676-6 6-6zm3.281 3.281L22 22.563l-2.281-2.282l-1.438 1.438l3 3l.719.687l.719-.687l4-4z" />
                  </svg> -->
                    <i
                      class="las la-map-marked-alt icons"
                      style="color: #b38e38"
                    />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t("sectors.tourism_guide") }}
                </h4>
              </div>
              <b-row class="mt-1">
                <!-- sucess -->
                <b-col
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-check-double text-success la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_success") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uegt[0].treatment_completed_successfully }}
                    </p>
                  </div>
                </b-col>
                <!-- failed -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-times text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_failed") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uegt[2].treatment_completed_failure }}
                    </p>
                  </div>
                </b-col>
                <hr class="py-1" />
                <!-- on_process -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-hourglass text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_on") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uegt[1].on_hold }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- ueh -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon" style="background-color: #ffe4e6">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      viewBox="0 0 32 32"
                    >
                      <path
                        fill="#cc6565"
                        d="M4 3v26h11v-4h2v4h11V3zm2 2h20v22h-7v-4h-6v4H6zm2 2v2h4V7zm6 0v2h4V7zm6 0v2h4V7zM8 11v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 15v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 19v2h4v-2zm6 0v2h4v-2zm6 0v2h4v-2zM8 23v2h4v-2zm12 0v2h4v-2z"
                      />
                    </svg>
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t("sectors.hebergement") }}
                </h4>
              </div>
              <b-row class="mt-1">
                <!-- sucess -->
                <b-col
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-check-double text-success la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_success") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueh[0].treatment_completed_successfully }}
                    </p>
                  </div>
                </b-col>
                <!-- failed -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-times text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_failed") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueh[2].treatment_completed_failure }}
                    </p>
                  </div>
                </b-col>
                <hr class="py-1" />
                <!-- on_process -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-hourglass text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_on") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.ueh[1].on_hold }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          <!-- uer -->
          <b-col cols="12">
            <b-card>
              <div
                class="d-flex align-items-center justify-content-start w-100"
              >
                <div class="">
                  <div class="icon" style="background-color: #d4eff4">
                    <!--                 <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 32 32">
                    <path fill="#4762af"
                      d="m12.375 3.813l-.313.843l-8 21.907l-.218.593l1 1l.593-.218l21.907-8l.843-.313l-.218-.875s-1.41-5.629-5.25-9.469s-9.469-5.25-9.469-5.25zm1.219 2.468c1.136.352 4.836 1.586 7.687 4.438c2.852 2.851 4.086 6.55 4.438 7.687l-1.532.563a10.464 10.464 0 0 0-.437-1.407c-.61-1.511-1.746-3.496-3.781-5.53c-2.035-2.036-4.02-3.141-5.532-3.75c-.539-.216-1-.36-1.406-.47zm-1.219 3.407c.336.09.766.218 1.313.437c1.289.52 3.046 1.484 4.874 3.313c.536.535.977 1.05 1.375 1.562A2.004 2.004 0 0 0 18 17a2 2 0 0 0 2 2c.738 0 1.371-.402 1.719-1c.043.102.117.215.156.313c.227.562.348 1.007.438 1.343l-10 3.625A1.483 1.483 0 0 0 11 22.5a1.5 1.5 0 0 0-1.469 1.813l-2.906 1.062l3.5-9.594A2 2 0 0 0 11 16a2 2 0 0 0 .5-3.938zM14 17.5a1.5 1.5 0 1 0 0 3a1.5 1.5 0 0 0 0-3z" />
                  </svg> -->
                    <i class="las la-utensils icons" style="color: #4762af" />
                  </div>
                </div>
                <h4 class="mb-0 ml-1 text-clr">
                  {{ $t("sectors.restaurant") }}
                </h4>
              </div>
              <b-row class="mt-1">
                <!-- sucess -->
                <b-col
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-check-double text-success la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_success") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uer[0].treatment_completed_successfully }}
                    </p>
                  </div>
                </b-col>
                <!-- <hr class="py-1" /> -->
                <!-- failed -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-times text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_failed") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uer[2].treatment_completed_failure }}
                    </p>
                  </div>
                </b-col>
                <hr class="py-1" />
                <!-- on_process -->
                <b-col
                  cols="12"
                  class="d-flex align-items-center justify-content-between w-100"
                >
                  <div
                    class="d-flex align-items-center justify-content-start w-100"
                  >
                    <i
                      class="las la-hourglass text-danger la-lg"
                      aria-hidden="true"
                    />
                    <p class="title">
                      {{ $t("dashboard.treate_request_on") }}
                    </p>
                  </div>
                  <div>
                    <b-spinner small v-if="isLoading == true" />
                    <p class="weight-800 text-clr" v-else>
                      {{ listData.uer[1].on_hold }}
                    </p>
                  </div>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BFormGroup,
  BButton,
  BTable,
  BLink,
  BModal,
  VBModal,
  BBadge,
  BSpinner,
  VBToggle,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import { mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import localstorageService from "@/services/localstorage/localstorage.service";

// store modules and vuex utilities
// eslint-disable-next-line import/no-cycle
import etablissementsStoreModule from "@/store/etablissements";
// eslint-disable-next-line import/no-cycle
import statusStoreModule from "@/store/status";
// eslint-disable-next-line import/no-cycle
import plaintesStoreModules from "@/store/plaintes";

// eslint-disable-next-line import/no-cycle
import {
  registerStoreModule,
  // unregisterStoreModule,
} from "@/helpers/vuex-utils";

import PaginationComponent from "@/components/PaginationComponent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import tourismJobsStatsStoreModules from "@/store/tourism-jobs-stats";
import VueHtml2pdf from "vue-html2pdf";

export default {
  components: {
    // ProductsListFilters,
    // UserListAddNew,

    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCardText,
    // BCollapse,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BSpinner,
    BModal,
    vSelect,
    PaginationComponent,
    AppCollapse,
    AppCollapseItem,
    flatPickr,
    VueHtml2pdf,
  },

  directives: {
    "b-modal": VBModal,
    "b-toggle": VBToggle,
    Ripple,
  },

  data() {
    return {
      isLoading: false,
      listData: {
        ueh: null,
        uegt: null,
        ueav: null,
        uer: null,
      },
      isDownloading: false,
    };
  },

  watch: {
    $route: {
      immediate: true,
      async handler(val, old) {
        await this.getRequestscount();
      },
    },
  },

  setup() {
    const requiredStoreModules = [
      { path: "tourism-jobs-stats", module: tourismJobsStatsStoreModules },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);
    return {
      requiredStoreModules,
    };
  },
  methods: {
    ...mapActions("tourism-jobs-stats", {
      action_getRequestscount: "getRequestscount",
    }),
    // --------------------------------------------
    getRequestscount() {
      this.isLoading = true;
      this.action_getRequestscount()
        .then((response) => {
          console.log("response::: ⭕️", response);
          this.isLoading = false;
          this.listData = {
            ueh: response.data.UEH,
            uegt: response.data.UEGT,
            ueav: response.data.UEAV,
            uer: response.data.UER,
          };
          // console.log("this.listData::: ⭕️⭕️", this.listData);
        })
        .catch((error) => {
          console.log("error::: ", error);
          this.isLoading = false;
        });
    },
    async exportation_stat() {
      this.isDownloading = true;
      await this.$refs.printableStat.generatePdf();
      this.$refs.printableStat.$once("hasDownloaded", () => {
        this.isDownloading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Document téléchargé avec succès",
            icon: "ThumbsUpIcon",
            variant: "success",
          },
        });
        this.forPrint = false;
      });
    },
    // Recherche des plaintes
  },
};
</script>
<style lang="scss">
.invoice-preview-wrapper {
  .row.invoice-preview {
    .col-md-8 {
      max-width: 100%;
      flex-grow: 1;
    }

    .invoice-preview-card {
      .card-body:nth-of-type(2) {
        .row {
          > .col-12 {
            max-width: 50% !important;
          }

          .col-12:nth-child(2) {
            display: flex;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: 0 !important;
          }
        }
      }
    }
  }

  // Action Right Col
  .invoice-actions {
    display: none;
  }
}

.text-clr {
  color: black;
}

.weight-800 {
  font-weight: 800;
  font-size: 25px;
  margin-bottom: 0px;
}

.icon {
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.icons {
  font-size: 25px;
}

.title {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 13px;
  font-weight: 700;
}
</style>

import tourismJobsStatsService from '@/services/http/tourism-jobs-stats.service'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getRequestscount({ rootState }, data) {
      const res = await tourismJobsStatsService.getRequestscount(data)
      return res
    },

  },
}

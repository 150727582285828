import { BaseService } from './base.service'

export class TourismJobsStats extends BaseService {
  constructor() {
    super()
  }

  getRequestscount() {
    return new Promise((resolve, reject) => {
      this.axiosInstance
        .get('statistiques/demandes/counts_per_sector')
        .then(response => resolve(response))
        .catch(error => reject(error))
    })
  }


}

export default new TourismJobsStats()